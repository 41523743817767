.footer {
    font-family: 'Righteous', cursive;
    overflow-x: hidden;
}

.footer-primary {
    background: linear-gradient(90deg, rgb(20, 20, 20) 0%, rgb(20, 20, 20) 100%);
}

.footer-primary-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 28px;
    padding-bottom: 28px;
}

.container {
    width: 100%;
    max-width: calc(1410px + 240px);
    padding-left: 0;
    padding-right: 104px;
    margin: 0 auto;
}

.footer-right-wrapper,
.footer-left-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footer-right {
    display: inline;
    margin-right: 0;
}

.footer-left {
    display: inline;
    margin-left: 0;
}

.footer-logo-image {
    height: 150px;
    width: auto;
}

.footer-logo {
    display: flex;
    margin-top: 1px;
    margin-left: 73px;
}

.footer-location {
    margin: 0 100px;
    color: #ffffff;
}

.footer-location-title,
.footer-contact-info-title {
    margin-bottom: 0.25rem;
    letter-spacing: 0.15rem;
}

.footer-location ul,
.footer-contact-info ul {
    list-style-type: none;
}

.footer-address-info {
    font-style: normal;
    letter-spacing: 0.05rem;
}

.footer-contact-info {
    margin: 0;
    color: #ffffff;
}

.footer-email-info,
.footer-phone-info {
    color: #ffffff;
    text-decoration: none;
    font-size: 1rem;
    font-weight: 100;
    letter-spacing: 0.05rem;
}

.footer-link:hover,
.footer-email-info:hover,
.footer-phone-info:hover {
    color: #2E95EC;
    transition: all 0.4s ease-out;
}

.footer-list {
    columns: 2;
    margin: 0;
    list-style: none;
}

.footer-link {
    display: inline-block;
    /* padding-left: 80px; */
    margin-left: 80px;
    padding-bottom: 0.2rem;
    color: #ffffff;
    font-size: 1.3rem;
    transition: 0.15s ease-out;
    text-decoration: none;
    text-transform: uppercase;
}

/************************* CSS for Secondary Footer ***********************/

.footer-secondary {
    background: linear-gradient(90deg, rgb(40, 40, 40) 0%, rgb(40, 40, 40) 100%);
}

.footer-secondary-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px 0;
}

.footer-copyright-info {
    line-height: 1rem;
    margin-left: 95px;
    width: 50%;
}

.footer-copyright-info-title,
.footer-copyright-link {
    color: #8b8b8b;
    font-size: 0.75rem;
}

a {
    text-decoration: none;
    background-color: transparent;
}

.footer-social-apps-info {
    display: inline;
    margin-right: 0;
}

.footer-social-apps-info-list {
    list-style-type: none;
}

.footer-social-apps-info-item {
    display: inline-block;
}

.footer-social-apps-info-link {
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 32px;
    width: 32px;
    border: 2px solid #8b8b8b;
    padding: 6px;
    margin-left: 10px;
}

.footer-social-apps-info-link:hover {
    color: #2E95EC;
    transition: all 0.4s ease-out;
}

@media screen and (max-width: 1400px) {
    .footer-primary-inner {
        flex-direction: row-reverse;
    }

    .footer-logo {
        display: none;
    }

    /* CSS for footer-secondary */
    .footer-copyright-info {
        position: relative;
        right: 0.9rem;
    }
}

@media screen and (max-width: 1200px) {
    .footer-primary-inner {
        flex-direction: column-reverse;
    }

    .footer-right {
        width: 100%;
    }

    .footer-left {
        margin: 20px 20px;
    }

    .footer-left,
    .footer-contact-info {
        width: 100%;
        margin-left: -20px;
    }

    .footer-contact-info {
        margin-left: -46px;
        margin-top: 1px;
    }

    .footer-location {
        white-space: nowrap;
    }

    .footer-contact-info-title,
    .footer-location-title {
        font-size: 1.8rem;
    }

    .footer-address-info,
    .footer-email-info {
        font-size: 1rem;
    }

    .footer-link {
        font-size: 2rem;
    }

    .footer-left-wrapper,
    .footer-right-wrapper {
        position: relative;
        right: 1.9rem;
    }

    /* CSS for footer-secondary */
    .footer-copyright-info {
        position: relative;
        right: 2.8rem;
    }

    .footer-social-apps-info {
        position: relative;
        left: 3.3rem; 
    }
}

@media screen and (max-width: 780px) {
    .footer-link {
        font-size: 1.3rem;
    }

    .footer-contact-info-title,
    .footer-location-title {
        font-size: 1.2rem;
    }

    .footer-address-info,
    .footer-email-info,
    .footer-phone-info {
        font-size: 0.65rem;
    }

    .footer-contact-info-title {
        margin-top: 18px;
    }

    .footer-contact-info {
        position: relative;
        left: 0.55rem;
        bottom: 0.15rem;
    }

    .footer-phone-info {
        position: relative;
        bottom: 0.8rem;
    }

    .footer-email-info {
        position: relative;
        bottom: 0.5rem;
    }

    .footer-left {
        margin-bottom: -5px;
    }
}

@media screen and (max-width: 550px) {
    .footer-link {
        font-size: 1rem;
    }

    .footer-contact-info-title,
    .footer-location-title {
        font-size: 0.9rem;
    }

    .footer-address-info,
    .footer-email-info,
    .footer-phone-info {
        font-size: 0.50rem;
    }

    .footer-contact-info {
        position: relative;
        left: 0.77rem;
        bottom: 0rem;
    }

    /* CSS for footer-secondary */
    .footer-secondary-inner {
        padding: 20px 0;
    }

    .footer-copyright-info {
        width: 45%;
        line-height: 0.8;
    }

    .footer-copyright-info-title,
    .footer-copyright-info-title a {
        font-size: 0.65rem;
    }

    .footer-social-apps-info {
        width: 55%;
        text-align: right;
    }

    .footer-copyright-info-title,
    .footer-copyright-info-title a {
        position: relative;
        bottom: 0.2rem;
    }
}

@media screen and (max-width: 450px) {
    .footer-link {
        font-size: 1.3rem;
        margin-left: 10px;
        /* padding-left: 10px; */
        position: relative;
        left: 4.35rem;
    }

    .footer-item {
        width: 200%;
    }

    .footer-contact-info-title,
    .footer-location-title {
        font-size: 1.2rem;
    }

    .footer-email-info,
    .footer-phone-info,
    .footer-address-info {
        font-size: 0.7rem;
    }

    .footer-left-wrapper {
        flex-direction: column;
    }

    .footer-location,
    .footer-contact-info {
        width: 100%;
    }

    .footer-location {
        position: relative;
        left: 6.26rem;
        top: 0.4rem;
    }

    .footer-contact-info {
        position: relative;
        left: 7.74rem;
        top: 0.6rem;
    }

    /* CSS for footer-secondary */
    .footer-secondary-inner {
        flex-direction: column-reverse;
        align-items: flex-start;
    }

    .footer-social-apps-info {
        width: 100%;
        text-align: left;
        position: relative;
        bottom: 0.2rem;
        left: 2.5rem;
    }

    .footer-social-apps-info-list,
    .footer-social-apps-info-item {
        padding-left: 0;
    }

    .footer-copyright-info-title,
    .footer-copyright-info-title a {
        font-size: 0.65rem;
        position: relative;
        top: 0.3rem;
        white-space: nowrap;
    }
}

@media screen and (max-width: 396px) {

    /* CSS for footer-secondary */
    .footer-copyright-info-title,
    .footer-copyright-info-title a {
        font-size: 0.52rem;
    }
}

@media screen and (max-width: 350px) {
    .footer-link {
        font-size: 1.1rem;
    }

    .footer-left,
    .footer-right,
    .footer-copyright-info-title,
    .footer-copyright-info-title a,
    .footer-social-apps-info-list {
        position: relative;
        right: 1.5rem;
    }

    .footer-address-info,
    .footer-email-info,
    .footer-phone-info {
        font-size: 0.62rem;
    }

    /* CSS for footer-secondary */
    .footer-copyright-info-title,
    .footer-copyright-info-title a {
        font-size: 0.52rem;
    }
}
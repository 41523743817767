.contact-main {
    overflow-x: hidden;
}

.text-center {
    text-align: center;
}

.contact-hero {
    background-color: rgb(0, 0, 0, 0.5);
    position: relative;
    height: 78vh;
    background-image: url(../assets/contact_hero_img.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 100%;
    max-width: 100%;
    background-blend-mode: darken;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 1 !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact-hero-background-container {
    margin-inline: auto;
    width: min(90%, 70.5rem);
}

.contact-hero-content-title {
    font-size: 5rem;
    color: white;
    letter-spacing: 0rem;
    text-transform: uppercase;
    font-family: Arial, Helvetica, sans-serif;
}

.contact-hero-content-text {
    font-size: 3rem;
    color: white;
    letter-spacing: 0.1rem;
    font-family: 'Righteous', cursive;
}

.contact-hero-fieldset {
    border-radius: 0.7rem;
    border-width: 0.4rem;
    border-color: tan;
}

.contact-info {
    padding: 4rem 0;
    background-color: white;
}

.contact-info-background-container {
    margin-inline: auto;
    width: min(90%, 70.5rem);
}

.contact-info-content-title {
    font-size: 2.5rem;
    color: black;
    letter-spacing: 0.2rem;
    font-family: 'Righteous', cursive;
}

.contact-info-content-text-description {
    font-size: 1rem;
    color: black;
    font-family: 'Righteous', cursive;
}

.contact-info-fieldset {
    border-radius: 0.7rem;
    border-width: 0.4rem;
    border-color: #fff5d7;
}

.contact-phone-info,
.contact-email-info {
    color: white;
}

.contact-phone-info:hover,
.contact-email-info:hover {
    color: #2E95EC;
    transition: all 0.4s ease-out;
}

.contact-info-content-container {
    position: relative;
    display: flex;
    bottom: 60px;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 100%;
}

.contact-info-content-block {
    background-color: #252525;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 25vw;
    padding-left: 2vw;
    padding-right: 2vw;
    justify-content: space-between;
    font-family: 'Righteous', cursive;
}

.contact-info-content-block:nth-child(1),
.contact-info-content-block:nth-child(2) {
    margin: 0 1vw;
}

.contact-info-content-block-title {
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    position: relative;
    bottom: 0.5rem;
}

.contact-info-content-block-description {
    position: relative;
    top: 0.5rem;
}

.contact-info-content-block-title,
.contact-info-content-block-description {
    color: white;
}

.contact-address-info {
    font-style: normal;
}

/*********** CSS for Free Estimate Section *************/

.contact-free-estimate {
    padding-top: 4rem;
    padding-bottom: 4rem;
    background-color: #252525;
}

.contact-free-estimate-container {
    margin-inline: auto;
    width: min(90%, 82rem);
}

.contact-free-estimate-title {
    font-size: 1.8rem;
    color: white;
    font-family: 'Monda', sans-serif !important;
    text-transform: uppercase;
}

.contact-free-estimate-content {
    font-family: 'Monda', sans-serif !important;
    color: white;
    font-size: 1.2rem;
    line-height: 2em;
}

/*********** CSS for Contact Form *********************/
.contact-form {
    background-color: antiquewhite;
    padding-top: 0rem;
    padding-bottom: 0rem;
}

.contact-form-container {
    background-color: burlywood;
    width: 50vw;
    padding-top: 4rem;
    padding-bottom: 3rem;
    display: flex;
}

.contact-form-title {
    color: black;
    letter-spacing: 0rem;
    text-transform: uppercase;
    font-size: 1.4rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.contact-form-details {
    color: white;
}

input[name="user_name"],
input[name="user_email"],
input[name="user_phone"],
input[name="user_address"],
input[name="user_city"],
textarea[name="message"] {
    font-family: 'Monda', sans-serif !important;
    font-weight: 600;
    font-size: 1.3rem;
    padding-left: 0.2rem;
    height: 2.5rem;
    width: 39.5vw;
    font-style: normal;
    position: relative;
    border-width: 0.2rem;
    border-color: black;
    border-style: solid;
}

input[name="user_state"],
input[name="user_zipcode"] {
    font-family: 'Monda', sans-serif !important;
    font-weight: 600;
    font-size: 1.3rem;
    padding-left: 0.2rem;
    height: 2.5rem;
    font-style: normal;
    position: relative;
    border-width: 0.2rem;
    border-color: black;
    border-style: solid;
    width: 18.8vw;
    margin: 0 1vw;
}

textarea[name="message"] {
    resize: vertical;
    height: 10rem;
    padding-top: 0.2rem;
}

.contact-form-submit-button {
    width: 220px;
    height: 50px;
    border: none;
    outline: none;
    color: black;
    background: white;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
    font-size: 1.6rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: bold;
    text-transform: uppercase;
}

.contact-form-submit-button:hover {
    background-color: black;
    transition: all 0.4s ease-in-out;
    color: white;
}

/*********** CSS for Google Map Section *************/

.contact-google-map-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.contact-google-map-content {
    width: 100%;
}

@media screen and (orientation:landscape) {
    .contact-hero {
        padding: 16rem 0;
    }
}

@media screen and (max-width: 1500px) {
    .contact-info-content-block-description {
        font-size: 0.8rem;
    }

    .contact-form-container {
        width: 60vw;
    }

    input[name="user_name"],
    input[name="user_email"],
    input[name="user_phone"],
    input[name="user_address"],
    input[name="user_city"],
    textarea[name="message"] {
        width: 46vw;
    }

    input[name="user_state"],
    input[name="user_zipcode"] {
        width: 22vw;
    }

    @media screen and (max-width: 1200px) {
        .contact-info-content-block-description,
        .contact-address-info {
            font-size: 0.7rem;
        }

        .contact-info-content-block {
            width: 30vw;
        }

        .contact-form-container {
            width: 60vw;
        }

        @media screen and (max-width: 1100px) {
            .contact-hero {
                background-attachment: scroll !important;
                height: 40vh;
            }

            @media screen and (max-width: 900px) {
                .contact-info-content-container {
                    position: relative;
                    flex-direction: column;
                    bottom: 4.5rem;
                }

                .contact-info-content-block:nth-child(2) {
                    position: relative;
                    top: 1rem;
                }

                .contact-info-content-block-title {
                    font-size: 0.8rem;
                }

                .contact-info-content-block-description,
                .contact-address-info {
                    font-size: 1rem;
                }

                .contact-info-content-block {
                    width: 60vw;
                }

                .contact-form-title {
                    font-size: 1.2rem;
                }

                input[name="user_name"],
                input[name="user_email"],
                input[name="user_phone"],
                input[name="user_address"],
                input[name="user_city"],
                textarea[name="message"] {
                    font-size: 1.3rem;
                    width: 58vw;
                }

                input[name="user_state"],
                input[name="user_zipcode"] {
                    font-size: 1.3rem;
                    width: 28vw;
                }

                .contact-form-container {
                    width: 70vw;
                }

                @media screen and (max-width: 800px) {
                    input[name="user_name"],
                    input[name="user_email"],
                    input[name="user_phone"],
                    input[name="user_address"],
                    input[name="user_city"],
                    textarea[name="message"] {
                        width: 52vw;
                    }

                    input[name="user_state"],
                    input[name="user_zipcode"] {
                        width: 25vw;
                    }

                    .contact-form-container {
                        width: 70vw;
                    }

                    @media screen and (max-width: 700px) {
                        .contact-hero-content-title {
                            font-size: 4rem;
                        }

                        .contact-hero-content-text {
                            font-size: 2rem;
                        }

                        input[name="user_name"],
                        input[name="user_email"],
                        input[name="user_phone"],
                        input[name="user_address"],
                        input[name="user_city"],
                        textarea[name="message"] {
                            width: 60vw;
                        }

                        input[name="user_state"],
                        input[name="user_zipcode"] {
                            width: 29vw;
                        }

                        .contact-form-container {
                            width: 80vw;
                        }

                        @media screen and (max-width: 600px) {
                            .contact-form-title {
                                font-size: 1rem;
                            }

                            input[name="user_name"],
                            input[name="user_email"],
                            input[name="user_phone"],
                            input[name="user_address"],
                            input[name="user_city"],
                            textarea[name="message"] {
                                width: 62vw;
                            }

                            input[name="user_state"],
                            input[name="user_zipcode"] {
                                width: 30vw;
                            }

                            .contact-info-content-block-description,
                            .contact-address-info {
                                font-size: 0.8rem;
                            }

                            .contact-info-content-block {
                                width: 60vw;
                            }

                            @media screen and (max-width: 500px) {
                                .contact-hero-content-title {
                                    font-size: 3.2rem;
                                }

                                .contact-hero-content-text {
                                    font-size: 1.8rem;
                                }

                                input[name="user_name"],
                                input[name="user_email"],
                                input[name="user_phone"],
                                input[name="user_address"],
                                input[name="user_city"],
                                textarea[name="message"] {
                                    font-size: 1.2rem;
                                    width: 70vw;
                                }

                                input[name="user_state"],
                                input[name="user_zipcode"] {
                                    font-size: 1.2rem;
                                    width: 34vw;
                                }

                                .contact-info-content-block {
                                    width: 70vw;
                                }

                                @media screen and (max-width: 450px) {
                                    .contact-info-content-block-description,
                                    .contact-address-info {
                                        font-size: 0.75rem;
                                    }

                                    .contact-info-content-block {
                                        width: 70vw;
                                    }
                                }

                                @media screen and (max-width: 400px) {
                                    .contact-info-content-block-description,
                                    .contact-address-info {
                                        font-size: 0.7rem;
                                    }

                                    .contact-hero-content-title {
                                        font-size: 2.8rem;
                                    }

                                    .contact-hero-content-text {
                                        font-size: 1.5rem;
                                    }

                                    .contact-form-title {
                                        font-size: 0.9rem;
                                    }

                                    .contact-info-content-title {
                                        font-size: 2rem;
                                    }

                                    input[name="user_name"],
                                    input[name="user_email"],
                                    input[name="user_phone"],
                                    input[name="user_address"],
                                    input[name="user_city"],
                                    textarea[name="message"] {
                                        font-size: 1.1rem;
                                    }

                                    input[name="user_state"],
                                    input[name="user_zipcode"] {
                                        font-size: 1.1rem;
                                    }

                                    .contact-form-submit-button {
                                        width: 200px;
                                        height: 50px;
                                        font-size: 1.5rem;
                                    }

                                    @media screen and (max-width: 350px) {
                                        .contact-hero-content-title {
                                            font-size: 2.3rem;
                                        }

                                        .contact-hero-content-text {
                                            font-size: 1.3rem;
                                        }

                                        .contact-form-title {
                                            font-size: 0.9rem;
                                        }

                                        .contact-form-submit-button {
                                            width: 180px;
                                            height: 50px;
                                            font-size: 1.5rem;
                                        }

                                        .contact-info-content-block-description,
                                        .contact-address-info {
                                            font-size: 0.65rem;
                                        }

                                        .contact-info-content-block {
                                            width: 75vw;
                                        }

                                        .contact-info-content-text-description {
                                            font-size: 0.9rem;
                                        }

                                        .contact-info-content-title {
                                            font-size: 1.8rem;
                                        }
                                    }

                                    @media screen and (max-width: 300px) {
                                        .contact-hero-content-title {
                                            font-size: 2.1rem;
                                        }

                                        .contact-hero-content-text {
                                            font-size: 1.2rem;
                                        }

                                        .contact-info-content-block-description,
                                        .contact-address-info {
                                            font-size: 0.6rem;
                                        }

                                        .contact-info-content-block {
                                            width: 75vw;
                                        }

                                        .contact-info-content-text-description {
                                            font-size: 0.8rem;
                                        }

                                        .contact-info-content-title {
                                            font-size: 1.6rem;
                                        }

                                        input[name="user_name"],
                                        input[name="user_email"],
                                        input[name="user_phone"],
                                        input[name="user_address"],
                                        input[name="user_city"],
                                        textarea[name="message"] {
                                            font-size: 1rem;
                                        }

                                        input[name="user_state"],
                                        input[name="user_zipcode"] {
                                            font-size: 1rem;
                                        }

                                        .contact-form-submit-button {
                                            width: 160px;
                                            height: 46px;
                                            font-size: 1.5rem;
                                        }

                                        .contact-form-title {
                                            font-size: 0.8rem;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 40em) {
    .contact-hero-fieldset {
        border-radius: 0.7rem;
        border-width: 0.5rem;
        border-color: tan;
    }

    .contact-info {
        padding: 6rem 0;
        background-color: white;
    }

    .contact-info-content-title {
        font-size: 3rem;
        color: black;
        letter-spacing: 0.2rem;
    }

    .contact-info-content-text-description {
        font-size: 1.1rem;
        color: black;
    }

    .contact-info-fieldset {
        border-radius: 0.7rem;
        border-width: 0.5rem;
        border-color: #fff5d7;
    }

    .contact-free-estimate {
        padding-top: 4rem;
        padding-bottom: 4rem;
        background-color: #252525;
    }

    .contact-free-estimate-title {
        font-size: 3rem;
        color: white;
        font-family: 'Monda', sans-serif !important;
        text-transform: uppercase;
    }

    .contact-free-estimate-content {
        font-family: 'Monda', sans-serif !important;
        color: white;
        font-size: 1.5rem;
        line-height: 2em;
    }
}
.services-main {
    overflow-x: hidden;
}

.text-center {
    text-align: center;
}

.services-hero-background {
    background-color: rgb(0, 0, 0, 0.5);
    position: relative;
    height: 78vh;
    text-align: center;
    background-image: url(../assets/services_hero_img.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 100%;
    background-blend-mode: darken;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 1 !important;

    /* CSS to align text */
    display: flex;
    justify-content: center;
    align-items: center;
}

.services-hero-content-title {
    text-transform: uppercase;
    color: white;
    font-size: 5rem;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
}

.services-intro {
    padding-top: 5rem;
    padding-bottom: 5rem;
    background-color: rgb(233, 235, 238);
}

.services-intro-title {
    text-transform: uppercase;
    font-family: 'Monda', sans-serif !important;
    font-size: 2.5rem;
    color: black;
    width: min(90%, 69rem);
    text-align: left;
}

.services-intro-description {
    font-size: 1.3rem;
    font-family: Arial, Helvetica, sans-serif;
    margin-inline: auto;
    width: min(90%, 67rem);
    text-align: left;
    line-height: 1.5em;
}

.services-intro-contact-button {
    width: 300px;
    height: 50px;
    border: none;
    outline: none;
    color: white;
    background: black;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
    font-size: 1.6rem;
    font-family: 'Righteous', cursive;
    text-transform: uppercase;
}

.services-intro-contact-button:hover {
    background-color: rgb(200, 200, 200);
    transition: all 0.4s ease-in-out;
    color: black;
}

.services-provided-information {
    padding-top: 5rem;
    padding-bottom: 5rem;
    background-color: white;
}

.services-provided-information-title {
    text-transform: uppercase;
    font-family: 'Monda', sans-serif !important;
    font-size: 2.5rem;
    color: black;
    width: min(90%, 47rem);
    text-align: left;
}

.services-provided-information-title-underline {
    width: 50rem;
    background-color: black;
    height: 5px;
}

.services-exterior-title {
    text-transform: uppercase;
    font-family: 'Monda', sans-serif !important;
    font-size: 2rem;
    color: black;
    width: min(90%, 47rem);
    text-align: left;
    line-height: 2.2em;
}

.services-exterior-content {
    width: min(90%, 46.6rem);
    position: relative;
    left: 2.6rem;
}

.services-exterior-content-list {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.8rem;
    color: black;
    text-align: left;
    list-style: square;
    line-height: 1.5em;
}

.services-interior-content {
    width: min(90%, 46.6rem);
    position: relative;
    left: 2.6rem;
}

.services-interior-content-list {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.8rem;
    color: black;
    text-align: left;
    list-style: square;
    line-height: 1.5em;
}

.services-interior-title {
    text-transform: uppercase;
    font-family: 'Monda', sans-serif !important;
    font-size: 2rem;
    color: black;
    width: min(90%, 47rem);
    text-align: left;
    line-height: 2.5em;
}

.services-interior-content-nested-list {
    list-style: circle;
    position: relative;
    left: 1.3rem;
    font-size: 1.8rem;
}

.services-exterior-content-nested-list {
    list-style: circle;
    position: relative;
    left: 1.3rem;
    font-size: 1.8rem;
}

@media screen and (orientation:landscape) {
    .services-hero-background {
        padding: 16rem 0;
    }
}

@media screen and (max-width: 1100px) {
    .services-hero-background {
        background-attachment: scroll !important;
        height: 40vh;
    } 
}

@media screen and (max-width: 500px) {
    .services-hero-content-title {
        font-size: 4rem;
    }
}

@media screen and (max-width: 450px) {
    .services-exterior-content-list,
    .services-interior-content-list,
    .services-interior-content-nested-list,
    .services-exterior-content-nested-list {
        font-size: 1.4rem;
    }
}

@media screen and (max-width: 400px) {
    .services-hero-content-title {
        font-size: 3.5rem;
    }
}

@media screen and (max-width: 350px) {
    .services-hero-content-title {
        font-size: 3rem;
    }

    .services-intro-title {
        font-size: 2rem;
    }

    .services-intro-description {
        font-size: 1.1rem;
    }

    .services-intro-contact-button {
        width: 240px;
        height: 50px;
        font-size: 1.4rem;
    }

    .services-exterior-content-list,
    .services-interior-content-list,
    .services-interior-content-nested-list,
    .services-exterior-content-nested-list {
        font-size: 1.2rem;
    }
}

@media screen and (max-width: 300px) {
    .services-hero-content-title {
        font-size: 2.8rem;
    }

    .services-provided-information-title {
        font-size: 2.2rem;
    }

    .services-exterior-title {
        font-size: 1.8rem;
    }

    .services-exterior-content-list,
    .services-interior-content-list,
    .services-interior-content-nested-list,
    .services-exterior-content-nested-list {
        font-size: 1.4rem;
    }
}
.header {
  background: linear-gradient(90deg, rgb(0, 0, 0) 0%, rgb(0, 0, 0) 100%);
  height: 80px;
  font-family: 'Righteous', cursive;
}

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 80px;
  background: linear-gradient(90deg, rgb(0, 0, 0) 0%, rgb(0, 0, 0) 100%);
}

.navbar .navbar-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

.navbar-container {
  width: 100%;
  max-width: calc(1410px + 240px);
  padding-left: 93.5px;
  padding-right: 88.1px;
  margin: 0 auto;
}

.navbar-logo {
  color: #ffffff;
  font-size: 2rem;
  white-space: nowrap;
}

.fa-screwdriver-wrench {
  margin-left: 0.4rem;
  margin-top: 0.3rem;
  font-size: 1.8rem;
}

.navbar-sticky {
  flex-grow: 1;
  flex-shrink: 1;
}

.navbar-menu {
  display: grid;
  grid-template-columns: repeat(6, auto);
  grid-gap: 10px;
  list-style: none;
  margin: 0;
  padding: 0;
  justify-content: end;
  align-items: center;
}

.navbar-item {
  position: relative;
  padding: 0;
  margin: 0;
}

.navbar-urls {
  color: #fff;
  text-decoration: none;
  display: flex;
  align-items: center;
  position: sticky;
  top: 1rem;
  padding: 0.7rem;
  font-size: 1.2rem;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
}

.navbar-urls:hover {
  background: #2E95EC;
  color: #000000;
  border-bottom-style: solid;
  border-bottom-width: 0.3rem;
  border-bottom-color: #ffff00;
  border-top-style: solid;
  border-top-width: 0.3rem;
  border-top-color: #ffff00;
  transition: all 0.4s ease-out;
}

.navbar-menu-icon {
  display: none;
}

@media screen and (max-width: 1400px) {
  .navbar-logo {
    position: relative;
    right: 0.87rem;
  }
}

@media screen and (max-width: 1200px) {
  .NavbarItems {
    position: relative;
  }

  .navbar-menu {
    background-color: #202020;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: unset;
    text-align: left;
    transform: scale(1, 0);
    transform-origin: top;
    height: 100vh;
    justify-content: flex-start;
  }

  .navbar-menu.active {
    background-color: #202020;
    left: 0;
    transform: unset;
    transform-origin: top;
    transition: transform 400ms ease-in-out;
  }

  .navbar-urls {
    border-bottom-style: solid;
    border-bottom-width: 0.2rem;
    border-bottom-color: gray;
    font-size: 1.5rem;
  }

  .navbar-urls:hover {
    background: unset;
    color: #2E95EC;
    border-bottom-style: solid;
    border-bottom-width: 0.2rem;
    border-bottom-color: gray;
    border-top-style: unset;
    border-top-width: unset;
    border-top-color: unset;
    transition: all 0.4s ease-out;
  }

  .navbar-logo {
    position: relative;
    left: -2.71rem;
  }

  .navbar-menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0.3rem;
    transform: translate(-80%, 35%);
    cursor: pointer;
    color: #ffffff;
  }
}

@media screen and (max-width: 350px) {
  .navbar-logo {
    position: relative;
    left: -4.34rem;
  }

  .navbar-menu-icon {
    transform: translate(-30%, 35%);
  }

  .navbar-urls {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 300px) {
  .navbar-urls {
    font-size: 1.3rem;
  }
}

@media screen and (max-height: 450px) and (orientation:landscape) {
  .navbar-urls {
    font-size: 0.94rem;
  }
}

@media screen and (max-height: 350px) and (orientation:landscape) {
  .navbar-urls {
    font-size: 0.63rem;
  }
}
.about-main {
    overflow-x: hidden;
}

.text-center {
    text-align: center;
}

.about-hero-background-container {
    background-color: rgb(0, 0, 0, 0.5);
    position: relative;
    height: 78vh;
    background-image: url(../assets/about_hero_img.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 100%;
    background-blend-mode: darken;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 1 !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.about-hero-content-title {
    text-transform: uppercase;
    color: white;
    font-size: 5rem;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
}

.about-intro {
    padding-top: 5rem;
    padding-bottom: 5rem;
    background-color: white;
}

.about-intro-primary-container {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    max-width: 1200px;
}

.about-intro-row {
    display: flex;
    flex-direction: row;
    display: -webkit-box;
    display: -ms-flexbox;
    justify-content: space-between;
}

.about-intro-right-wrapper {
    position: relative;
    left: 4.1rem;
}

.about-intro-title {
    text-transform: uppercase;
    font-family: 'Monda', sans-serif !important;
    font-size: 2.5rem;
    color: black;
    text-align: left;
    letter-spacing: normal;
}

.about-contact-form-container {
    background-color: #525252;
    margin-top: 0.5rem;
    padding-top: 3.7rem;
    padding-bottom: 3.7rem;
    width: 26rem;
}

.about-contact-form-title {
    font-family: "Barlow Semi Condensed", sans-serif !important;
    text-transform: uppercase;
    font-size: 1.5rem;
    text-align: center;
    color: white;
}

.about-intro-description {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.3rem;
    text-align: left;
    line-height: 1.5em;
}

.about-contact-redirect, 
.about-phone-info {
    color: black;
}

.about-contact-redirect:hover, 
.about-phone-info:hover {
    color: #d39850;
    transition: all 0.4s ease-out;
}

textarea:focus,
input:focus {
    outline: none;
}

input[name="about_user_name"],
input[name="about_user_email"],
input[name="about_user_phone"],
input[name="about_user_address"],
input[name="about_user_city"],
input[name="about_user_state"],
input[name="about_user_zipcode"] {
    border-top-style: hidden;
    border-right-style: hidden;
    border-left-style: hidden;
    border-bottom-style: solid;
    border-bottom-color: white;
    border-bottom-width: 0.2rem;
    background-color: transparent;
    color: white;
    font-size: 1rem;
    margin-left: 2.6rem;
    width: 20.8rem;
    padding-bottom: 0.2rem;
}

textarea[name="about_message"] {
    border-top-style: solid;
    border-right-style: solid;
    border-left-style: solid;
    border-bottom-style: solid;
    border-color: black;
    border-width: 0.2rem;
    background-color: white;
    color: black;
    font-size: 1rem;
    margin-left: 2.6rem;
    width: 20.8rem;
    padding-left: 0.5rem;
    padding-top: 0.5rem;
    height: 10rem;
    resize: vertical;
}

.about-form-submit-button {
    width: 220px;
    height: 50px;
    border: none;
    outline: none;
    color: black;
    background: white;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
    font-size: 1.6rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
}

.about-form-submit-button:hover {
    background-color: black;
    transition: all 0.4s ease-in-out;
    color: white;
}

::placeholder {
    color: darkgray;
    opacity: 1;
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: darkgray;
}

::-ms-input-placeholder {
    /* Microsoft Edge */
    color: darkgray;
}

@media screen and (orientation:landscape) {
    .about-hero-background-container {
        padding: 16rem 0;
    }
}

@media screen and (max-width: 1400px) {
    .about-intro-row {
        flex-direction: column;
    }

    .about-intro-left-wrapper {
        margin-left: 3.2rem;
        overflow-wrap: break-word;
    }

    .about-intro-right-wrapper {
        -webkit-box-flex: unset;
        -ms-flex: unset;
        flex: unset;
        max-width: unset;
        margin-left: -0.9rem;
    }

    .about-intro-description {
        overflow-wrap: break-word;
    }

    .about-intro {
        padding-top: 5rem;
        padding-bottom: 0rem;
        background-color: white;
    }

    input[name="about_user_name"],
    input[name="about_user_email"],
    input[name="about_user_phone"],
    input[name="about_user_address"],
    input[name="about_user_city"],
    input[name="about_user_state"],
    input[name="about_user_zipcode"], 
    textarea[name="about_message"] {
        padding-left: 0.3rem;
    }
}

@media screen and (max-width: 1200px) {
    .about-intro-primary-container {
        flex-direction: column;
    }

    .about-intro-left-wrapper {
        padding-right: 5rem;
    }
}

@media screen and (max-width: 1100px) {
    .about-hero-background-container {
        background-attachment: scroll !important;
        height: 40vh;
    }
}


@media screen and (max-width: 600px) {
    .about-hero-content-title {
        font-size: 4.5rem;
    }
}

@media screen and (max-width: 540px) and (min-width: 540px) {
    .about-intro-title {
        font-size: 2.6rem;
    }

    .about-intro-description {
        font-size: 1.4rem;
    }

    .about-contact-form-title {
        font-size: 1.9rem;
    }

    .about-contact-form-container {
        width: 33.8rem;

        position: relative;
        right: 3.2rem;
    }

    input[name="about_user_name"],
    input[name="about_user_email"],
    input[name="about_user_phone"],
    input[name="about_user_address"],
    input[name="about_user_city"],
    input[name="about_user_state"],
    input[name="about_user_zipcode"], 
    textarea[name="about_message"] {
        margin-left: 3.75rem;
        width: 26.3rem;
    }

    .about-form-submit-button {
        width: 220px;
        height: 50px;
        font-size: 1.5rem;
    }
}

@media screen and (max-width: 500px) {
    .about-hero-content-title {
        font-size: 4rem;
    }

    .about-intro-title {
        font-size: 2.5rem;
    }

    .about-intro-description {
        font-size: 1.3rem;
    }

    .about-intro-left-wrapper {
        position: relative;
        right: 1rem;
    }

    .about-contact-form-container {
        width: 25rem;

        position: relative;
        right: 1rem;
    }

    input[name="about_user_name"],
    input[name="about_user_email"],
    input[name="about_user_phone"],
    input[name="about_user_address"],
    input[name="about_user_city"],
    input[name="about_user_state"],
    input[name="about_user_zipcode"] {
        margin-left: 2.1rem;
    }

    textarea[name="about_message"] {
        margin-left: 2.1rem;
    }
}

@media screen and (max-width: 480px) and (min-width: 480px) {
    .about-contact-form-title {
        font-size: 1.7rem;
    }

    .about-contact-form-container {
        width: 30rem;

        position: relative;
        right: 3.2rem;
    }

    input[name="about_user_name"],
    input[name="about_user_email"],
    input[name="about_user_phone"],
    input[name="about_user_address"],
    input[name="about_user_city"],
    input[name="about_user_state"],
    input[name="about_user_zipcode"],
    textarea[name="about_message"]  {
        margin-left: 3.25rem;
        width: 23.5rem;
    }

    .about-form-submit-button {
        width: 220px;
        height: 50px;
        font-size: 1.5rem;
    }
}

@media screen and (max-width: 450px) {
    .about-hero-content-title {
        font-size: 3.5rem;
    }

    .about-contact-form-title {
        font-size: 1.3rem;
    }

    .about-contact-form-container {
        width: 21.7rem;

        position: relative;
        right: 1rem;
    }

    input[name="about_user_name"],
    input[name="about_user_email"],
    input[name="about_user_phone"],
    input[name="about_user_address"],
    input[name="about_user_city"],
    input[name="about_user_state"],
    input[name="about_user_zipcode"] {
        margin-left: 1.9rem;
        width: 18rem;
    }

    textarea[name="about_message"] {
        margin-left: 1.9rem;
        width: 18rem;
    }
}

@media screen and (max-width: 415px) and (min-width: 414px) {
    .about-contact-form-title {
        font-size: 1.5rem;
    }

    .about-contact-form-container {
        width: 25.9rem;

        position: relative;
        right: 3.2rem;
    }

    input[name="about_user_name"],
    input[name="about_user_email"],
    input[name="about_user_phone"],
    input[name="about_user_address"],
    input[name="about_user_city"],
    input[name="about_user_state"],
    input[name="about_user_zipcode"],
    textarea[name="about_message"]  {
        margin-left: 2.6rem;
        width: 20.8rem;
    }

    .about-form-submit-button {
        width: 200px;
        height: 50px;
        font-size: 1.4rem;
    }
}

@media screen and (max-width: 412px) and (min-width: 412px) {
    .about-contact-form-title {
        font-size: 1.5rem;
    }

    .about-contact-form-container {
        width: 25.7rem;

        position: relative;
        right: 3.2rem;
    }

    input[name="about_user_name"],
    input[name="about_user_email"],
    input[name="about_user_phone"],
    input[name="about_user_address"],
    input[name="about_user_city"],
    input[name="about_user_state"],
    input[name="about_user_zipcode"],
    textarea[name="about_message"]  {
        margin-left: 2.5rem;
        width: 20.8rem;
    }

    .about-form-submit-button {
        width: 180px;
        height: 50px;
        font-size: 1.4rem;
    }
}

@media screen and (max-width: 412px) and (min-width: 411px) {
    .about-contact-form-title {
        font-size: 1.5rem;
    }

    .about-contact-form-container {
        width: 25.7rem;

        position: relative;
        right: 3.2rem;
    }

    input[name="about_user_name"],
    input[name="about_user_email"],
    input[name="about_user_phone"],
    input[name="about_user_address"],
    input[name="about_user_city"],
    input[name="about_user_state"],
    input[name="about_user_zipcode"],
    textarea[name="about_message"]  {
        margin-left: 2.5rem;
        width: 20.8rem;
    }

    .about-form-submit-button {
        width: 180px;
        height: 50px;
        font-size: 1.4rem;
    }
}

@media screen and (max-width: 400px) {
    .about-hero-content-title {
        font-size: 3.2rem;
    }

    .about-intro-title {
        font-size: 2.2rem;
    }

    .about-intro-description {
        font-size: 1.3rem;
    }

    .about-contact-form-title {
        font-size: 1.1rem;
    }

    .about-contact-form-container {
        width: 19rem;

        position: relative;
        right: 1rem;
    }

    input[name="about_user_name"],
    input[name="about_user_email"],
    input[name="about_user_phone"],
    input[name="about_user_address"],
    input[name="about_user_city"],
    input[name="about_user_state"],
    input[name="about_user_zipcode"] {
        margin-left: 1.9rem;
        width: 15.25rem;
        font-size: 1rem;
    }

    textarea[name="about_message"] {
        margin-left: 1.9rem;
        width: 15.25rem;
        font-size: 1rem;
    }

    .about-form-submit-button {
        width: 180px;
        height: 50px;
        font-size: 1.4rem;
    }
}

@media screen and (max-width: 394px) and (min-width: 393px) {
    .about-intro-title {
        font-size: 2.3rem;
    }

    .about-intro-description {
        font-size: 1.3rem;
    }

    .about-contact-form-title {
        font-size: 1.4rem;
    }

    .about-contact-form-container {
        width: 24.6rem;

        position: relative;
        right: 3.2rem;
    }

    input[name="about_user_name"],
    input[name="about_user_email"],
    input[name="about_user_phone"],
    input[name="about_user_address"],
    input[name="about_user_city"],
    input[name="about_user_state"],
    input[name="about_user_zipcode"], 
    textarea[name="about_message"] {
        margin-left: 2.6rem;
        width: 19.4rem;
    }

    .about-form-submit-button {
        width: 200px;
        height: 50px;
        font-size: 1.4rem;
    }
}

@media screen and (max-width: 391px) and (min-width: 390px) {
    .about-intro-title {
        font-size: 2.3rem;
    }

    .about-intro-description {
        font-size: 1.3rem;
    }

    .about-contact-form-title {
        font-size: 1.4rem;
    }

    .about-contact-form-container {
        width: 24.4rem;

        position: relative;
        right: 3.2rem;
    }

    input[name="about_user_name"],
    input[name="about_user_email"],
    input[name="about_user_phone"],
    input[name="about_user_address"],
    input[name="about_user_city"],
    input[name="about_user_state"],
    input[name="about_user_zipcode"], 
    textarea[name="about_message"] {
        margin-left: 2.5rem;
        width: 19.4rem;
    }

    .about-form-submit-button {
        width: 200px;
        height: 50px;
        font-size: 1.4rem;
    }
}

@media screen and (max-width: 384px) and (min-width: 384px) {
    .about-contact-form-title {
        font-size: 1.4rem;
    }

    .about-contact-form-container {
        width: 24rem;

        position: relative;
        right: 3.2rem;
    }

    input[name="about_user_name"],
    input[name="about_user_email"],
    input[name="about_user_phone"],
    input[name="about_user_address"],
    input[name="about_user_city"],
    input[name="about_user_state"],
    input[name="about_user_zipcode"] {
        margin-left: 2.3rem;
        width: 19.4rem;
    }

    textarea[name="about_message"] {
        margin-left: 2.3rem;
        width: 19.4rem;
    }

    .about-form-submit-button {
        width: 180px;
        height: 50px;
        font-size: 1.4rem;
    }
}

@media screen and (max-width: 376px) and (min-width: 375px) {
    .about-intro-title {
        font-size: 2.2rem;
    }

    .about-intro-description {
        font-size: 1.3rem;
    }

    .about-contact-form-title {
        font-size: 1.3rem;
    }

    .about-contact-form-container {
        width: 23.5rem;

        position: relative;
        right: 3.2rem;
    }

    input[name="about_user_name"],
    input[name="about_user_email"],
    input[name="about_user_phone"],
    input[name="about_user_address"],
    input[name="about_user_city"],
    input[name="about_user_state"],
    input[name="about_user_zipcode"], 
    textarea[name="about_message"] {
        margin-left: 2.75rem;
        width: 18rem;
    }

    .about-form-submit-button {
        width: 180px;
        height: 50px;
        font-size: 1.4rem;
    }
}

@media screen and (max-width: 360px) and (min-width: 360px) {
    .about-contact-form-title {
        font-size: 1.3rem;
    }

    .about-contact-form-container {
        width: 22.5rem;

        position: relative;
        right: 3.2rem;
    }

    input[name="about_user_name"],
    input[name="about_user_email"],
    input[name="about_user_phone"],
    input[name="about_user_address"],
    input[name="about_user_city"],
    input[name="about_user_state"],
    input[name="about_user_zipcode"] {
        margin-left: 2.2rem;
        width: 18.1rem;
        font-size: 1rem;
    }

    textarea[name="about_message"] {
        margin-left: 2.2rem;
        width: 18.1rem;
        font-size: 1rem;
    }

    .about-form-submit-button {
        width: 180px;
        height: 50px;
        font-size: 1.4rem;
    }
}

@media screen and (max-width: 354px) and (min-width: 353px) {
    .about-intro-title {
        font-size: 2.1rem;
    }

    .about-intro-description {
        font-size: 1.3rem;
    }

    .about-contact-form-title {
        font-size: 1.3rem;
    }

    .about-contact-form-container {
        width: 22.1rem;

        position: relative;
        right: 3.2rem;
    }

    input[name="about_user_name"],
    input[name="about_user_email"],
    input[name="about_user_phone"],
    input[name="about_user_address"],
    input[name="about_user_city"],
    input[name="about_user_state"],
    input[name="about_user_zipcode"], 
    textarea[name="about_message"] {
        margin-left: 2.1rem;
        width: 18rem;
    }

    .about-form-submit-button {
        width: 180px;
        height: 50px;
        font-size: 1.4rem;
    }
}

@media screen and (max-width: 350px) {
    .about-hero-content-title {
        font-size: 3rem;
    }

    .about-intro-title {
        font-size: 1.8rem;
    }

    .about-intro-description {
        font-size: 1.2rem;
    }

    .about-contact-form-title {
        font-size: 1.1rem;
    }

    .about-contact-form-container {
        width: 18.7rem;

        position: relative;
        right: 3.2rem;
    }

    input[name="about_user_name"],
    input[name="about_user_email"],
    input[name="about_user_phone"],
    input[name="about_user_address"],
    input[name="about_user_city"],
    input[name="about_user_state"],
    input[name="about_user_zipcode"] {
        margin-left: 1.75rem;
        width: 15.2rem;
        font-size: 1rem;
    }

    textarea[name="about_message"] {
        margin-left: 1.75rem;
        width: 15.2rem;
        font-size: 1rem;
    }

    .about-form-submit-button {
        width: 180px;
        height: 50px;
        font-size: 1.4rem;
    }
}

@media screen and (max-width: 320px) and (min-width: 320px) {
    .about-contact-form-title {
        font-size: 1.15rem;
    }

    .about-contact-form-container {
        width: 20rem;

        position: relative;
        right: 3.2rem;
    }

    input[name="about_user_name"],
    input[name="about_user_email"],
    input[name="about_user_phone"],
    input[name="about_user_address"],
    input[name="about_user_city"],
    input[name="about_user_state"],
    input[name="about_user_zipcode"] {
        margin-left: 2rem;
        width: 16rem;
        font-size: 1rem;
    }

    textarea[name="about_message"] {
        margin-left: 2rem;
        width: 16rem;
        font-size: 1rem;
    }

    .about-form-submit-button {
        width: 180px;
        height: 50px;
        font-size: 1.4rem;
    }
}

@media screen and (max-width: 300px) {
    .about-hero-content-title {
        font-size: 2.5rem;
    }

    .about-intro-title {
        font-size: 1.6rem;
    }

    .about-intro-description {
        font-size: 1rem;
    }

    .about-contact-form-title {
        font-size: 1.1rem;
    }

    .about-contact-form-container {
        width: 17.5rem;

        position: relative;
        right: 3.2rem;
    }

    input[name="about_user_name"],
    input[name="about_user_email"],
    input[name="about_user_phone"],
    input[name="about_user_address"],
    input[name="about_user_city"],
    input[name="about_user_state"],
    input[name="about_user_zipcode"] {
        margin-left: 1.2rem;
        width: 15.2rem;
        font-size: 1rem;
    }

    textarea[name="about_message"] {
        margin-left: 1.2rem;
        width: 15.2rem;
        font-size: 1rem;
    }

    .about-form-submit-button {
        width: 180px;
        height: 50px;
        font-size: 1.4rem;
    }
}

@media screen and (max-width: 280px) and (min-width: 280px) {
    .about-intro-title {
        font-size: 1.5rem;
    }

    .about-intro-description {
        font-size: 1rem;
    }

    .about-contact-form-title {
        font-size: 1rem;
    }

    .about-contact-form-container {
        width: 17.5rem;

        position: relative;
        right: 3.2rem;
    }

    input[name="about_user_name"],
    input[name="about_user_email"],
    input[name="about_user_phone"],
    input[name="about_user_address"],
    input[name="about_user_city"],
    input[name="about_user_state"],
    input[name="about_user_zipcode"],
    textarea[name="about_message"] {
        margin-left: 1.85rem;
        width: 13.8rem;
    }

    .about-form-submit-button {
        width: 140px;
        height: 50px;
        font-size: 1.2rem;
    }
}

.text-center {
    text-align: center;
}

.gallery-coming-soon {
    background-color: antiquewhite;
    height: 78vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.gallery-coming-soon-content-title {
    font-family: 'Righteous', cursive;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    font-size: 2rem;
}

@media screen and (max-width: 1100px) {
    .gallery-coming-soon {
        background-attachment: scroll !important;
        height: 40vh;
    }
}

@media screen and (max-width: 450px) {
    .gallery-coming-soon-content-title {
        font-size: 1.8rem;
    }
}

@media screen and (max-width: 400px) {
    .gallery-coming-soon-content-title {
        font-size: 1.6rem;
    }
}

@media screen and (max-width: 350px) {
    .gallery-coming-soon-content-title {
        font-size: 1.4rem;
    }
}

@media screen and (max-width: 300px) {
    .gallery-coming-soon-content-title {
        font-size: 1.25rem;
    }
}